import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {ImageToBase64} from '../'; // Use ImageToBase64 function

export const ExportEnergyStatement = async (data, fileName) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();

  // Loop over sheets data
  for (let index = 0; index < data.length; index++) {
    const sheetData = data[index];
    const worksheet = workbook.addWorksheet(sheetData.title);

    // If it's the first sheet, handle the logos and custom header starting from row 2
    if (index === 0) {
      // Set row height for the first row to 50px
      worksheet.getRow(1).height = 50;

      // Handle customer logo or name
      if (sheetData.customer.logo?.file) {
        const logo1 = await ImageToBase64(sheetData.customer.logo.file);
        const imageId1 = workbook.addImage({
          base64: logo1,
          extension: sheetData.customer.logo.extension,
        });
        worksheet.addImage(imageId1, {
          tl: {
            col: 0,
            row: 0
          },
          ext: {
            width: sheetData.customer.logo.width,
            height: sheetData.customer.logo.height
          },
          editAs: 'oneCell',
        });
      } else {
        const cell = worksheet.getCell('A1');
        cell.value = sheetData.customer.name;
        cell.font = {
          size: 20,
          bold: true
        };
        worksheet.mergeCells(1, 1, 1, 3); // Merge the first three columns for the customer name
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        };
      }

      // Handle partner logo or name
      const lastColumn = sheetData.columns.length;
      if (sheetData.partner.logo?.file) {
        const logo2 = await ImageToBase64(sheetData.partner.logo.file);
        const imageId2 = workbook.addImage({
          base64: logo2,
          extension: sheetData.partner.logo.extension,
        });

        worksheet.mergeCells(1, lastColumn - 2, 1, lastColumn); // Merge last three cells in the first row

        worksheet.addImage(imageId2, {
          tl: {
            col: lastColumn - 6,
            row: 0
          },
          ext: {
            width: sheetData.partner.logo.width,
            height: sheetData.partner.logo.height
          },
          editAs: 'oneCell',
          hyperlinks: {
            hyperlink: sheetData.partner.logo.link
          },
        });
      } else {
        const cell = worksheet.getCell(`A${lastColumn}`);
        cell.value = sheetData.partner.name;
        cell.font = {
          size: 20,
          bold: true
        };
        worksheet.mergeCells(1, lastColumn - 2, 1, lastColumn);
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        };
      }

      // Remove outlines (borders) from the first row by setting border color to white
      const removeBorderFromRow = (row) => {
        row.eachCell({
          includeEmpty: true
        }, (cell) => {
          cell.border = {
            top: {
              style: 'thin',
              color: {
                argb: 'FFFFFFFF'
              }
            },
            left: {
              style: 'thin',
              color: {
                argb: 'FFFFFFFF'
              }
            },
            bottom: {
              style: 'thin',
              color: {
                argb: 'FFFFFFFF'
              }
            },
            right: {
              style: 'thin',
              color: {
                argb: 'FFFFFFFF'
              }
            },
          };
        });
      };
      removeBorderFromRow(worksheet.getRow(1)); // Ensure borders are removed from the first row

      // Manually add headers to row 2 with bold text and thick borders
      const headerRow = worksheet.getRow(2);
      sheetData.columns.forEach((col, colIndex) => {
        const cell = headerRow.getCell(colIndex + 1);
        cell.value = col.headerName;
        cell.font = {
          bold: true
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
        cell.border = {
          top: {
            style: 'thick'
          },
          left: {
            style: 'thick'
          },
          bottom: {
            style: 'thick'
          },
          right: {
            style: 'thick'
          },
        };
        worksheet.getColumn(colIndex + 1).width = col.width ? col.width / 10 : 10;
      });

      // Add the data starting from row 3 and apply background colors for the first sheet only
      sheetData.rows.forEach((row, rowIndex) => {
        const rowData = sheetData.columns.map((col) => row[col.field]);
        const addedRow = worksheet.addRow(rowData);

        addedRow.eachCell((cell, colNumber) => {
          // Apply borders to each cell
          cell.border = {
            top: {
              style: 'thin'
            },
            left: {
              style: 'thin'
            },
            bottom: {
              style: 'thin'
            },
            right: {
              style: 'thin'
            },
          };

          // Apply bold and background color for "total" column
          if (sheetData.columns[colNumber - 1].field === 'total') {
            cell.font = {
              bold: true
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFD8E4BC'
              }, // Background color for "total"
            };
          }

          // Apply background color for columns after "total"
          if (colNumber > sheetData.columns.findIndex(col => col.field === 'total') + 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFEBF1DE'
              }, // Background color for columns after "total"
            };
          }
        });
      });

      // Add SUM formula to the last row with background color and bold font
      const lastRow = worksheet.lastRow.number + 1;
      const sumRow = worksheet.getRow(lastRow);

      for (let colIndex = 1; colIndex <= sheetData.columns.length; colIndex++) {
        const cell = sumRow.getCell(colIndex);
        if (colIndex > sheetData.columns.findIndex(col => col.field === 'total')) {
          const letter = worksheet.getColumn(colIndex).letter;
          cell.value = {
            formula: `SUM(${letter}3:${letter}${lastRow - 1})`
          };
          cell.font = {
            bold: true
          };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: 'FFD8E4BC'
            }
          };
          cell.border = {
            top: {
              style: 'thick'
            },
            left: {
              style: 'thick'
            },
            bottom: {
              style: 'thick'
            },
            right: {
              style: 'thick'
            },
          };
        }
      }

      // Merge columns before the "total" column in the last row
      const totalColIndex = sheetData.columns.findIndex(col => col.field === 'total') + 1;
      if (totalColIndex > 1) {
        worksheet.mergeCells(lastRow, 1, lastRow, totalColIndex - 1);
        const mergedCell = sumRow.getCell(1);
        mergedCell.value = '';
        mergedCell.border = {
          top: {
            style: 'thick'
          },
          left: {
            style: 'thick'
          },
          bottom: {
            style: 'thick'
          },
          right: {
            style: 'thick'
          },
        };
      }

      // Freeze panes for the first sheet: freeze header row (row 2) and first two columns
      worksheet.views = [{
        state: 'frozen',
        xSplit: 2,
        ySplit: 2
      }];
    } else {
      // For other sheets
      const columns = sheetData.columns.map((col) => ({
        header: col.headerName,
        key: col.field,
        width: col.width ? col.width / 10 : 10,
      }));
      worksheet.columns = columns;

      // Make the headers bold and apply thick borders
      const headerRow = worksheet.getRow(1);
      headerRow.font = {
        bold: true
      };
      headerRow.eachCell((cell) => {
        cell.border = {
          top: {
            style: 'thick'
          },
          left: {
            style: 'thick'
          },
          bottom: {
            style: 'thick'
          },
          right: {
            style: 'thick'
          },
        };
      });

      // Add the data starting from row 2
      sheetData.rows.forEach((row, rowIndex) => {
        const rowData = sheetData.columns.map((col) => row[col.field]);
        const addedRow = worksheet.addRow(rowData);

        addedRow.eachCell((cell, colNumber) => {
          // Apply borders to each cell
          cell.border = {
            top: {
              style: 'thin'
            },
            left: {
              style: 'thin'
            },
            bottom: {
              style: 'thin'
            },
            right: {
              style: 'thin'
            },
          };

          // Apply bold font for the "sum" column
          if (sheetData.columns[colNumber - 1].field === 'sum') {
            cell.font = {
              bold: true
            };
          }
        });

        // Make the "summary" row bold
        if (row.ts === "summary") {
          addedRow.font = {
            bold: true
          };
        }
      });

      // Freeze panes for other sheets: freeze the first two rows and the first two columns
      worksheet.views = [{
        state: 'frozen',
        xSplit: 2,
        ySplit: 2
      }];
    }

    // Set auto filter on the headers (row 2 for first sheet, row 1 for others)
    worksheet.autoFilter = {
      from: {
        row: index === 0 ? 2 : 1,
        column: 1
      },
      to: {
        row: index === 0 ? 2 : 1,
        column: sheetData.columns.length
      },
    };
  }

  // Export the Excel workbook to a blob
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/octet-stream'
  });
  saveAs(blob, fileName);
};
