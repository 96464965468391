import {SendSlackMessage} from "./";

export const SendSlackLogin = () => {
  if (process.env.REACT_APP_NODE_ENV === "production") {
    const slackErrorData = {
      channel: "fabeke-login",
      type: "success",
      heading: `Login activity: After login, user landed on ${window.location.href}`,
      // detail: `${userName} (${userEmail}) has logged in on the ${process.env.REACT_APP_NODE_ENV} environment`,
      fields: []
    };
    SendSlackMessage(slackErrorData).then();
  }
  return null;
};
