import {gql} from "@apollo/client";

export const ENERGY_DATA = gql`
query EnergyData (
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
    $isClimate: Boolean
  ) {
    energyData(
        meterType: $meterType
        customer: $customer
        fromDate: $fromDate
        toDate: $toDate
        resolution: $resolution
        address: $address
        meteringPointId: $meteringPointId
        isClimate: $isClimate
    ) {
        meteringPointId
        customer
        type
        address
        fromDate
        toDate
        data {
            ts
            label
            consumptionV
        }
        total {
            consumptionV
        }
    }
}
`;