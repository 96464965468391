import {useQuery} from "@apollo/client";
import {useAuth, useUser} from "../../app/providers";
import {ME_QUERY} from "./commands";
import {setCacheWithExpiry} from "../../utils";

// import { useContext } from "react";
// import { useSnackbar } from "notistack";

/**
 *
 * @param onCompleted
 * @param onError
 */
export function useMe(onCompleted = () => null, onError = () => null) {
  // const { enqueueSnackbar } = useSnackbar();
  // const { TextDomainContext } = useTextDomainContext();
  // const { gettext } = useContext(TextDomainContext);

  const {setUser} = useUser();

  const {unsetToken} = useAuth();

  const {
    data,
    loading,
    refetch
  } = useQuery(ME_QUERY, {
    onCompleted: ({comMe}) => {
      try {
        setUser({
          ...comMe
        });

        const key = "comMe";
        const cacheTTL = 3600; // 3600 = 1 hour
        setCacheWithExpiry(key, comMe, cacheTTL);

        onCompleted(comMe);
      } catch (error) {
        // SendSlackDebug("comeMe onComplete catch");
        unsetToken();
      }
    },
    onError: () => {
      // SendSlackDebug("comeMe onError");
      unsetToken();
      onError();

      // enqueueSnackbar(
      //   gettext(
      //     "Unable To Validate The Current User. Logging Out!. Please Login again."
      //   ),
      //   {
      //     variant: "error"
      //   }
      // );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  // const { data: data2, refetch: refetchColors } = useQuery(USE_GRAPH_COLOR, {
  //   onCompleted: ({ userGraphColor }) => {
  //     try {
  //       setColors({
  //         ...userGraphColor
  //       });
  //     } catch (error) { /* empty */
  //     }
  //   },
  //   onError: () => {
  //     onError();
  //   },
  //   fetchPolicy: "cache-and-network"
  // });

  return {
    refetchMe: refetch,
    user: data?.comMe,
    loading,
  };
}
