import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import {useAuth, useUser} from "app/providers";
import {deleteAll} from "app/common/IndexedDb";
// import { LayoutSplashScreen } from "_metronic/layout";
import {Navigate, useLocation} from "react-router";
import {useApolloClient} from "@apollo/client";

export const Logout = (props) => {
  const location = useLocation();
  const {state} = location;
  const {
    user,
    setUser
  } = useUser();
  const {unsetToken} = useAuth();
  const client = useApolloClient();
  const storageLocale = localStorage.getItem("languageSelect");
  localStorage.clear();
  storageLocale && localStorage.setItem("languageSelect", storageLocale);

  useEffect(() => {
    if (!state || !state?.loginAs) {
      setUser(null);
      unsetToken();
    }
  }, [setUser, state, unsetToken]);

  ReactGA.event({
    category: "Authentication",
    action: "Logout",
    label: user.email?.replace("@", "[at]")
  });

  deleteAll(["cache"]).then(() => {
    client.clearStore().then(() => {
      setUser(null);
      unsetToken();
      if (state?.loginAs) {
        return <Navigate to={"/dashboard"}/>;
      } else {
        return <>
          {/* <LayoutSplashScreen /> */}
        </>;
      }
    });
  });

  // return <LayoutSplashScreen />;
};

