import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import React, {useContext, useEffect, useState} from "react";
import {useTextDomainContext} from "app/providers";
import {onError} from "@apollo/client/link/error";
import {useAuth} from "../AuthProvider";
import {useNavigate} from "react-router";
import {useSnackbar} from "notistack";
import {SendSlackError} from "../../../utils";
import {persistCache} from "apollo3-cache-persist";
import IndexedDBWrapper from './IndexedDBWrapper';

const {REACT_APP_API_URL_GRAPHQL} = process.env;

const GraphQLProvider = ({children}) => {
  const {token} = useAuth();
  const {TextDomainContext} = useTextDomainContext();
  const {gettext} = useContext(TextDomainContext);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setupClient = async () => {
      try {
        const cache = new InMemoryCache();
        // const indexedDBWrapper = new IndexedDBWrapper('apollo-persist-cache');
        const indexedDBWrapper = new IndexedDBWrapper('cache');

        await persistCache({
          cache,
          storage: indexedDBWrapper,
        });

        const authMiddleware = new ApolloLink((operation, forward) => {
          if (token) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${token}`,
              },
            });
          }
          return forward(operation);
        });

        const httpLink = createHttpLink({
          uri: REACT_APP_API_URL_GRAPHQL,
        });

        const errorLink = onError(({
          graphQLErrors,
          networkError
        }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({message}) => {
              if (message) {
                switch (message) {
                  case "440":
                    enqueueSnackbar(gettext("The session has been expired"), {
                      variant: "warning",
                    });
                    navigate("/logout");
                    break;
                  default:
                    if (process.env.REACT_APP_NODE_ENV !== "production") {
                      enqueueSnackbar("Backend Error: " + message, {
                        variant: "error",
                      });
                      SendSlackError("Backend Error: " + message);
                    }
                    break;
                }
              }
            });
          }
          if (networkError) {
            enqueueSnackbar("Network error: " + networkError.message, {
              variant: "error",
            });
          }
        });

        const client = new ApolloClient({
          link: authMiddleware.concat(from([errorLink, httpLink])),
          cache,
          connectToDevTools: true,
          defaultOptions: {
            watchQuery: {
              nextFetchPolicy(
                currentFetchPolicy,
                {
                  // Either "after-fetch" or "variables-changed", indicating why the
                  // nextFetchPolicy function was invoked.
                  reason,
                  // The rest of the options (currentFetchPolicy === options.fetchPolicy).
                  options,
                  // The original value of options.fetchPolicy, before nextFetchPolicy was
                  // applied for the first time.
                  initialFetchPolicy,
                  // The ObservableQuery associated with this client.watchQuery call.
                  observable,
                }
              ) {
                // When variables change, the default behavior is to reset
                // options.fetchPolicy to context.initialFetchPolicy. If you omit this logic,
                // your nextFetchPolicy function can override this default behavior to
                // prevent options.fetchPolicy from changing in this case.
                if (reason === 'variables-changed') {
                  return initialFetchPolicy;
                }

                if (
                  currentFetchPolicy === 'network-only' ||
                  currentFetchPolicy === 'cache-and-network'
                ) {
                  // Demote the network policies (except "no-cache") to "cache-first"
                  // after the first request.
                  return 'cache-first';
                }

                // Leave all other fetch policies unchanged.
                return currentFetchPolicy;
              },
            },
          },
        });

        setClient(client);
      } catch (error) {
        console.error("Error setting up Apollo Client:", error);
        enqueueSnackbar("Error setting up GraphQL client", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    setupClient();
  }, [token, enqueueSnackbar, gettext, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading state while the client is being set up
  }

  if (!client) {
    return <div>Error initializing GraphQL client</div>; // Handle the case where the client fails to initialize
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
