import {gql} from "@apollo/client";

export const CLIMATE_DATA = gql`
query ClimateData (
    $meterType: String!
    $meteringPointId: String
    $address: String
    $customer: String!
    $fromDate: DateTime!
    $toDate: DateTime!
    $resolution: String!
  ) {
    climateData(
        meterType: $meterType
        customer: $customer
        fromDate: $fromDate
        toDate: $toDate
        resolution: $resolution
        meteringPointId: $meteringPointId
        address: $address
    ) {
        meteringPointId
        customer
        type
        address
        fromDate
        toDate
        data {
            ts
            label
            consumptionV
            conversionRate
            co2
        }
        total {
            consumptionV
            co2
        }
    }
}
`;