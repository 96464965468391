import React, {useEffect, useState} from "react";
import {FabekeDropdown} from "./FabekeDropdown";
import {useUser} from "../../providers";
import PropTypes from "prop-types";
import {useCompanies, useGetUserCompanies, useUpdateProfile} from "../../../hooks";
import {HasPermission} from "../../../utils";

const codesAllCompanies = ["all-companies-full", "all-Companies-readonly", "everything-full", "everything-readonly", "developer"];

/**
 * Customer dropdown component
 * @param {object} props props
 * @returns {Element} React Component
 * @class
 */
export const CustomerDropdown = (props) => {
  const {user} = useUser();
  const {updateProfile} = useUpdateProfile();
  const {
    currentTeamId,
    currentTeam
  } = user || {
  };
  const {companiesList} = useGetUserCompanies();
  const {customers} = useCompanies();
  const allowAllCompanies = HasPermission(codesAllCompanies);

  // const { TextDomainContext } = useTextDomainContext();
  // const { gettext } = useContext(TextDomainContext);

  const propsDefault = {
    defaultValue: currentTeamId,
    componentLabel: ""
  };

  //region UseState [React Hooks]
  const [customerValue, setCustomerValue] = useState(props.defaultValue ? props.defaultValue : propsDefault.defaultValue);
  const [componentLabel, setComponentLabel] = useState(props.componentLabel ? props.componentLabel : propsDefault.componentLabel);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  //endregion UseState

  //region UseEffect [React Hooks]
  useEffect(() => {
    if (!customerValue && selectedCompanies.length) {
      updateProfile({
        currentTeam: selectedCompanies[0].name,
        currentTeamId: selectedCompanies[0].id
      });
    }
  }, [customerValue, selectedCompanies, updateProfile]);

  useEffect(() => {
    if (allowAllCompanies) {
      if (customers.length > 0) {
        setSelectedCompanies(customers);
      }
    } else {
      if (companiesList.length > 0) {
        setSelectedCompanies(companiesList);
      }
    }

  }, [allowAllCompanies, companiesList, customers]);

  useEffect(() => {
    if (typeof props.defaultValue === "undefined") {
      setCustomerValue(propsDefault.defaultValue);
    } else {
      setCustomerValue(props.defaultValue);
    }
  }, [props.defaultValue, propsDefault.defaultValue]);

  useEffect(() => {
    if (typeof props.componentLabel === "undefined") {
      setComponentLabel(propsDefault.componentLabel);
    } else {
      setComponentLabel(props.componentLabel);
    }
  }, [props.componentLabel, propsDefault.componentLabel]);

  useEffect(() => {
    const customerArray = [];
    let selectedCustomerId = "";
    selectedCompanies.map(customerRow => {
      if (customerRow.name === currentTeam) {
        selectedCustomerId = customerRow.id;
      }
      const oneCustomer = {
        label: customerRow.name,
        value: customerRow.id
      };
      customerArray.push(oneCustomer);
      return true;
    });
    setCompanies(customerArray);
    if (propsDefault.defaultValue) {
      setCustomerValue(propsDefault.defaultValue);
    } else {
      setCustomerValue(selectedCustomerId);
    }
    // eslint -disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies, currentTeam, propsDefault.defaultValue]);

  //endregion useEffect

  const handleChange = event => {
    if (typeof props.onChange === "function") {
      props.onChange(event);
    }
  };
  //endregion Handlers

  return (
    <FabekeDropdown
      options={companies}
      componentLabel={componentLabel}
      defaultValue={customerValue}
      onChange={handleChange}
    />
  );

};

CustomerDropdown.propTypes = {
  componentLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};
