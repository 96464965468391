import {useQuery} from "@apollo/client";
import {ENERGY_DATA} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param variables
 * @param onCompleted
 * @param onError
 */
export function useEnergyData(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(ENERGY_DATA, {
    variables,
    onCompleted: ({energyData}) => {
      onCompleted(energyData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data: data?.energyData?.data ? data?.energyData?.data : [],
    total: data?.energyData?.total ? data?.energyData?.total : [],
    loading
  };
}
