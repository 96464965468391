import {useQuery} from "@apollo/client";
import {CLIMATE_DATA} from "./commands";
import {enqueueSnackbar} from "notistack";

/**
 *
 * @param variables
 * @param onCompleted
 * @param onError
 */
export function useClimateData(
  variables,
  onCompleted = () => {
  },
  onError = () => {

  }
) {
  const {
    data,
    loading
  } = useQuery(CLIMATE_DATA, {
    variables,
    onCompleted: ({climateData}) => {
      onCompleted(climateData);
    },
    onError: (err) => {
      onError();

      enqueueSnackbar(
        err.message,
        {
          variant: "error"
        }
      );
    },
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    errorPolicy: "all"
  });

  return {
    data: data?.climateData?.data,
    total: data?.climateData?.total,
    loading
  };
}
